const CountriesList = [
  { value: 'IN', region: 'in/en', reg: 'in', name: 'India' },
  { value: 'US', region: 'us/en', reg: 'us', name: 'United States' },
  { value: 'AF', region: 'global/en', reg: 'global', name: 'Afghanistan' },
  { value: 'AL', region: 'uk/en', reg: 'uk', name: 'Albania' },
  { value: 'DZ', region: 'global/en', reg: 'global', name: 'Algeria' },
  { value: 'AS', region: 'sg/en', reg: 'sg', name: 'American Samoa' },
  { value: 'AD', region: 'global/en', reg: 'global', name: 'Andorra' },
  { value: 'AO', region: 'global/en', reg: 'global', name: 'Angola' },
  { value: 'AI', region: 'us/en', reg: 'us', name: 'Anguilla' },
  { value: 'AQ', region: 'global/en', reg: 'global', name: 'Antarctica' },
  { value: 'AG', region: 'us/en', reg: 'us', name: 'Antigua and Barbuda' },
  { value: 'AR', region: 'us/en', reg: 'us', name: 'Argentina' },
  { value: 'AM', region: 'global/en', reg: 'global', name: 'Armenia' },
  { value: 'AW', region: 'us/en', reg: 'us', name: 'Aruba' },
  { value: 'AU', region: 'au/en', reg: 'au', name: 'Australia' },
  { value: 'AT', region: 'uk/en', reg: 'uk', name: 'Austria' },
  { value: 'AZ', region: 'global/en', reg: 'global', name: 'Azerbaijan' },
  { value: 'BS', region: 'us/en', reg: 'us', name: 'Bahamas' },
  { value: 'BH', region: 'global/en', reg: 'global', name: 'Bahrain' },
  { value: 'BD', region: 'sg/en', reg: 'sg', name: 'Bangladesh' },
  { value: 'BB', region: 'us/en', reg: 'us', name: 'Barbados' },
  { value: 'BY', region: 'global/en', reg: 'global', name: 'Belarus' },
  { value: 'BE', region: 'uk/en', reg: 'uk', name: 'Belgium' },
  { value: 'BZ', region: 'us/en', reg: 'us', name: 'Belize' },
  { value: 'BJ', region: 'global/en', reg: 'global', name: 'Benin' },
  { value: 'BM', region: 'us/en', reg: 'us', name: 'Bermuda' },
  { value: 'BT', region: 'sg/en', reg: 'sg', name: 'Bhutan' },
  { value: 'BO', region: 'us/en', reg: 'us', name: 'Bolivia' },
  { value: 'BA', region: 'uk/en', reg: 'uk', name: 'Bosnia and Herzegovina' },
  { value: 'BW', region: 'global/en', reg: 'global', name: 'Botswana' },
  { value: 'BV', region: 'us/en', reg: 'us', name: 'Bouvet Island' },
  { value: 'BR', region: 'us/en', reg: 'us', name: 'Brazil' },
  { value: 'IO', region: 'global/en', reg: 'global', name: 'British Indian Ocean Territory' },
  { value: 'VG', region: 'us/en', reg: 'us', name: 'British Virgin Islands' },
  { value: 'BN', region: 'sg/en', reg: 'sg', name: 'Brunei' },
  { value: 'BG', region: 'uk/en', reg: 'uk', name: 'Bulgaria' },
  { value: 'BF', region: 'global/en', reg: 'global', name: 'Burkina Faso' },
  { value: 'BI', region: 'global/en', reg: 'global', name: 'Burundi' },
  { value: 'KH', region: 'sg/en', reg: 'sg', name: 'Cambodia' },
  { value: 'CM', region: 'global/en', reg: 'global', name: 'Cameroon' },
  { value: 'CA', region: 'ca/en', reg: 'ca', name: 'Canada' },
  { value: 'CV', region: 'global/en', reg: 'global', name: 'Cabo Verde' },
  { value: 'KY', region: 'us/en', reg: 'us', name: 'Cayman Islands' },
  { value: 'CF', region: 'global/en', reg: 'global', name: 'Central African Republic' },
  { value: 'TD', region: 'global/en', reg: 'global', name: 'Chad' },
  { value: 'CL', region: 'us/en', reg: 'us', name: 'Chile' },
  { value: 'CN', region: 'sg/en', reg: 'sg', name: 'China' },
  { value: 'CX', region: 'sg/en', reg: 'sg', name: 'Christmas Island' },
  { value: 'CP', region: 'us/en', reg: 'us', name: 'Clipperton Island' },
  { value: 'CC', region: 'sg/en', reg: 'sg', name: 'Cocos [Keeling] Islands' },
  { value: 'CO', region: 'us/en', reg: 'us', name: 'Colombia' },
  { value: 'KM', region: 'global/en', reg: 'global', name: 'Comoros' },
  { value: 'CG', region: 'global/en', reg: 'global', name: 'Congo - Brazzaville' },
  { value: 'CD', region: 'global/en', reg: 'global', name: 'Congo - Kinshasa' },
  { value: 'CK', region: 'sg/en', reg: 'sg', name: 'Cook Islands' },
  { value: 'CR', region: 'us/en', reg: 'us', name: 'Costa Rica' },
  { value: 'HR', region: 'uk/en', reg: 'uk', name: 'Croatia' },
  { value: 'CU', region: 'us/en', reg: 'us', name: 'Cuba' },
  { value: 'CY', region: 'uk/en', reg: 'uk', name: 'Cyprus' },
  { value: 'CZ', region: 'uk/en', reg: 'uk', name: 'Czech Republic' },
  { value: 'CI', region: 'global/en', reg: 'global', name: 'Côte d’Ivoire' },
  { value: 'DK', region: 'uk/en', reg: 'uk', name: 'Denmark' },
  { value: 'DJ', region: 'global/en', reg: 'global', name: 'Djibouti' },
  { value: 'DM', region: 'us/en', reg: 'us', name: 'Dominica' },
  { value: 'DO', region: 'us/en', reg: 'us', name: 'Dominican Republic' },
  { value: 'EC', region: 'us/en', reg: 'us', name: 'Ecuador' },
  { value: 'EG', region: 'global/en', reg: 'global', name: 'Egypt' },
  { value: 'SV', region: 'us/en', reg: 'us', name: 'El Salvador' },
  { value: 'GQ', region: 'global/en', reg: 'global', name: 'Equatorial Guinea' },
  { value: 'ER', region: 'global/en', reg: 'global', name: 'Eritrea' },
  { value: 'EE', region: 'uk/en', reg: 'uk', name: 'Estonia' },
  { value: 'ET', region: 'global/en', reg: 'global', name: 'Ethiopia' },
  { value: 'FK', region: 'us/en', reg: 'us', name: 'Falkland Islands' },
  { value: 'FO', region: 'global/en', reg: 'global', name: 'Faroe Islands' },
  { value: 'FJ', region: 'sg/en', reg: 'sg', name: 'Fiji' },
  { value: 'FI', region: 'uk/en', reg: 'uk', name: 'Finland' },
  { value: 'FR', region: 'uk/en', reg: 'uk', name: 'France' },
  { value: 'GF', region: 'us/en', reg: 'us', name: 'French Guiana' },
  { value: 'PF', region: 'sg/en', reg: 'sg', name: 'French Polynesia' },
  { value: 'TF', region: 'global/en', reg: 'global', name: 'French Southern Territories' },
  { value: 'GA', region: 'global/en', reg: 'global', name: 'Gabon' },
  { value: 'GM', region: 'global/en', reg: 'global', name: 'Gambia' },
  { value: 'GE', region: 'global/en', reg: 'global', name: 'Georgia' },
  { value: 'DE', region: 'uk/en', reg: 'uk', name: 'Germany' },
  { value: 'GH', region: 'global/en', reg: 'global', name: 'Ghana' },
  { value: 'GI', region: 'global/en', reg: 'global', name: 'Gibraltar' },
  { value: 'GR', region: 'global/en', name: 'Greece' },
  { value: 'GG', region: 'global/en', reg: 'global', name: 'Guernsey' },
  { value: 'GL', region: 'us/en', reg: 'us', name: 'Greenland' },
  { value: 'GD', region: 'us/en', reg: 'us', name: 'Grenada' },
  { value: 'GP', region: 'us/en', reg: 'us', name: 'Guadeloupe' },
  { value: 'GU', region: 'sg/en', reg: 'sg', name: 'Guam' },
  { value: 'GT', region: 'us/en', reg: 'us', name: 'Guatemala' },
  { value: 'GN', region: 'global/en', reg: 'global', name: 'Guinea' },
  { value: 'GW', region: 'global/en', reg: 'global', name: 'Guinea-Bissau' },
  { value: 'GY', region: 'us/en', reg: 'us', name: 'Guyana' },
  { value: 'HT', region: 'us/en', reg: 'us', name: 'Haiti' },
  { value: 'HM', region: 'sg/en', reg: 'sg', name: 'Heard Island and McDonald Islands' },
  { value: 'HN', region: 'us/en', reg: 'us', name: 'Honduras' },
  { value: 'HK', region: 'sg/en', reg: 'sg', name: 'Hong Kong SAR China' },
  { value: 'HU', region: 'uk/en', reg: 'uk', name: 'Hungary' },
  { value: 'IS', region: 'uk/en', reg: 'uk', name: 'Iceland' },
  { value: 'ID', region: 'sg/en', reg: 'sg', name: 'Indonesia' },
  { value: 'IR', region: 'global/en', reg: 'global', name: 'Iran' },
  { value: 'IQ', region: 'global/en', reg: 'global', name: 'Iraq' },
  { value: 'IE', region: 'uk/en', reg: 'uk', name: 'Ireland' },
  { value: 'IL', region: 'uk/en', reg: 'uk', name: 'Israel' },
  { value: 'IT', region: 'uk/en', reg: 'uk', name: 'Italy' },
  { value: 'JM', region: 'us/en', reg: 'us', name: 'Jamaica' },
  { value: 'JP', region: 'sg/en', reg: 'sg', name: 'Japan' },
  { value: 'JO', region: 'global/en', reg: 'global', name: 'Jordan' },
  { value: 'KZ', region: 'global/en', reg: 'global', name: 'Kazakhstan' },
  { value: 'KE', region: 'global/en', reg: 'global', name: 'Kenya' },
  { value: 'KI', region: 'sg/en', reg: 'sg', name: 'Kiribati' },
  { value: 'XK', region: 'uk/en', reg: 'uk', name: 'Kosovo' },
  { value: 'KW', region: 'global/en', reg: 'global', name: 'Kuwait' },
  { value: 'KG', region: 'global/en', reg: 'global', name: 'Kyrgyzstan' },
  { value: 'LA', region: 'sg/en', reg: 'sg', name: 'Laos' },
  { value: 'LV', region: 'uk/en', reg: 'uk', name: 'Latvia' },
  { value: 'LB', region: 'global/en', reg: 'global', name: 'Lebanon' },
  { value: 'LS', region: 'global/en', reg: 'global', name: 'Lesotho' },
  { value: 'LR', region: 'global/en', reg: 'global', name: 'Liberia' },
  { value: 'LY', region: 'global/en', reg: 'global', name: 'Libya' },
  { value: 'LI', region: 'uk/en', reg: 'uk', name: 'Liechtenstein' },
  { value: 'LT', region: 'uk/en', reg: 'uk', name: 'Lithuania' },
  { value: 'LU', region: 'uk/en', reg: 'uk', name: 'Luxembourg' },
  { value: 'MO', region: 'sg/en', reg: 'sg', name: 'Macau SAR China' },
  { value: 'MK', region: 'uk/en', reg: 'uk', name: 'Macedonia' },
  { value: 'MG', region: 'global/en', reg: 'global', name: 'Madagascar' },
  { value: 'MW', region: 'global/en', reg: 'global', name: 'Malawi' },
  { value: 'MY', region: 'sg/en', reg: 'sg', name: 'Malaysia' },
  { value: 'MV', region: 'global/en', reg: 'global', name: 'Maldives' },
  { value: 'ML', region: 'global/en', reg: 'global', name: 'Mali' },
  { value: 'MT', region: 'uk/en', reg: 'uk', name: 'Malta' },
  { value: 'MH', region: 'sg/en', reg: 'sg', name: 'Marshall Islands' },
  { value: 'MQ', region: 'us/en', reg: 'us', name: 'Martinique' },
  { value: 'MR', region: 'global/en', reg: 'global', name: 'Mauritania' },
  { value: 'MU', region: 'global/en', reg: 'global', name: 'Mauritius' },
  { value: 'YT', region: 'global/en', reg: 'global', name: 'Mayotte' },
  { value: 'MX', region: 'us/en', reg: 'us', name: 'Mexico' },
  { value: 'FM', region: 'sg/en', reg: 'sg', name: 'Micronesia' },
  { value: 'MD', region: 'global/en', reg: 'global', name: 'Moldova' },
  { value: 'MC', region: 'global/en', reg: 'global', name: 'Monaco' },
  { value: 'MN', region: 'sg/en', name: 'Mongolia' },
  { value: 'ME', region: 'uk/en', reg: 'uk', name: 'Montenegro' },
  { value: 'MS', region: 'us/en', reg: 'us', name: 'Montserrat' },
  { value: 'MA', region: 'global/en', reg: 'global', name: 'Morocco' },
  { value: 'MZ', region: 'global/en', reg: 'global', name: 'Mozambique' },
  { value: 'MM', region: 'sg/en', reg: 'sg', name: 'Myanmar [Burma]' },
  { value: 'NA', region: 'global/en', reg: 'global', name: 'Namibia' },
  { value: 'NR', region: 'sg/en', reg: 'sg', name: 'Nauru' },
  { value: 'NP', region: 'global/en', reg: 'global', name: 'Nepal' },
  { value: 'NL', region: 'uk/en', reg: 'uk', name: 'Netherlands' },
  { value: 'AN', region: 'us/en', reg: 'us', name: 'Netherlands Antilles' },
  { value: 'NC', region: 'sg/en', reg: 'sg', name: 'New Caledonia' },
  { value: 'NZ', region: 'sg/en', reg: 'sg', name: 'New Zealand' },
  { value: 'NI', region: 'us/en', reg: 'us', name: 'Nicaragua' },
  { value: 'NE', region: 'global/en', reg: 'global', name: 'Niger' },
  { value: 'NG', region: 'global/en', reg: 'global', name: 'Nigeria' },
  { value: 'NU', region: 'sg/en', reg: 'sg', name: 'Niue' },
  { value: 'NF', region: 'sg/en', reg: 'sg', name: 'Norfolk Island' },
  { value: 'MP', region: 'sg/en', reg: 'sg', name: 'Northern Mariana Islands' },
  { value: 'KP', region: 'sg/en', reg: 'sg', name: 'North Korea' },
  { value: 'NO', region: 'uk/en', reg: 'uk', name: 'Norway' },
  { value: 'OM', region: 'global/en', reg: 'global', name: 'Oman' },
  { value: 'PK', region: 'global/en', reg: 'global', name: 'Pakistan' },
  { value: 'PW', region: 'sg/en', reg: 'sg', name: 'Palau' },
  { value: 'PS', region: 'global/en', reg: 'global', name: 'Palestinian Territories' },
  { value: 'PA', region: 'us/en', reg: 'us', name: 'Panama' },
  { value: 'PG', region: 'sg/en', reg: 'sg', name: 'Papua New Guinea' },
  { value: 'PY', region: 'us/en', reg: 'us', name: 'Paraguay' },
  { value: 'PE', region: 'us/en', reg: 'us', name: 'Peru' },
  { value: 'PH', region: 'sg/en', reg: 'sg', name: 'Philippines' },
  { value: 'PN', region: 'sg/en', reg: 'sg', name: 'Pitcairn Islands' },
  { value: 'PL', region: 'uk/en', reg: 'uk', name: 'Poland' },
  { value: 'PT', region: 'uk/en', reg: 'uk', name: 'Portugal' },
  { value: 'PR', region: 'us/en', reg: 'us', name: 'Puerto Rico' },
  { value: 'QA', region: 'global/en', reg: 'global', name: 'Qatar' },
  { value: 'RO', region: 'uk/en', reg: 'uk', name: 'Romania' },
  { value: 'RU', region: 'global/en', reg: 'global', name: 'Russia' },
  { value: 'RW', region: 'global/en', reg: 'global', name: 'Rwanda' },
  { value: 'RE', region: 'global/en', reg: 'global', name: 'Réunion' },
  { value: 'SH', region: 'global/en', reg: 'global', name: 'Saint Helena' },
  { value: 'KN', region: 'us/en', reg: 'us', name: 'Saint Kitts and Nevis' },
  { value: 'LC', region: 'us/en', reg: 'us', name: 'Saint Lucia' },
  { value: 'PM', region: 'us/en', reg: 'us', name: 'Saint Pierre and Miquelon' },
  { value: 'VC', region: 'us/en', reg: 'us', name: 'Saint Vincent and the Grenadines' },
  { value: 'WS', region: 'sg/en', reg: 'sg', name: 'Samoa' },
  { value: 'SM', region: 'global/en', reg: 'global', name: 'San Marino' },
  { value: 'SA', region: 'global/en', reg: 'global', name: 'Saudi Arabia' },
  { value: 'SN', region: 'global/en', reg: 'global', name: 'Senegal' },
  { value: 'RS', region: 'uk/en', reg: 'uk', name: 'Serbia' },
  { value: 'SC', region: 'global/en', reg: 'global', name: 'Seychelles' },
  { value: 'SL', region: 'global/en', reg: 'global', name: 'Sierra Leone' },
  { value: 'SG', region: 'sg/en', reg: 'sg', name: 'Singapore' },
  { value: 'SK', region: 'uk/en', reg: 'uk', name: 'Slovakia' },
  { value: 'SI', region: 'uk/en', reg: 'uk', name: 'Slovenia' },
  { value: 'SB', region: 'sg/en', reg: 'sg', name: 'Solomon Islands' },
  { value: 'SO', region: 'global/en', reg: 'global', name: 'Somalia' },
  { value: 'ZA', region: 'global/en', reg: 'global', name: 'South Africa' },
  { value: 'GS', region: 'us/en', reg: 'us', name: 'South Georgia and the South Sandwich Islands' },
  { value: 'KR', region: 'sg/en', reg: 'sg', name: 'South Korea' },
  { value: 'ES', region: 'uk/en', reg: 'uk', name: 'Spain' },
  { value: 'LK', region: 'sg/en', reg: 'sg', name: 'Sri Lanka' },
  { value: 'SD', region: 'global/en', reg: 'global', name: 'Sudan' },
  { value: 'SR', region: 'us/en', reg: 'us', name: 'Suriname' },
  { value: 'SJ', region: 'global/en', reg: 'global', name: 'Svalbard and Jan Mayen' },
  { value: 'SZ', region: 'global/en', reg: 'global', name: 'Swaziland' },
  { value: 'SE', region: 'uk/en', reg: 'uk', name: 'Sweden' },
  { value: 'CH', region: 'uk/en', reg: 'uk', name: 'Switzerland' },
  { value: 'SY', region: 'global/en', reg: 'global', name: 'Syria' },
  { value: 'ST', region: 'global/en', reg: 'global', name: 'São Tomé and Príncipe' },
  { value: 'TW', region: 'sg/en', reg: 'sg', name: 'Taiwan' },
  { value: 'TJ', region: 'global/en', reg: 'global', name: 'Tajikistan' },
  { value: 'TZ', region: 'global/en', reg: 'global', name: 'Tanzania' },
  { value: 'TH', region: 'sg/en', reg: 'sg', name: 'Thailand' },
  { value: 'TL', region: 'sg/en', reg: 'sg', name: 'Timor-Leste' },
  { value: 'TG', region: 'global/en', reg: 'global', name: 'Togo' },
  { value: 'TK', region: 'sg/en', reg: 'sg', name: 'Tokelau' },
  { value: 'TO', region: 'sg/en', reg: 'sg', name: 'Tonga' },
  { value: 'TT', region: 'us/en', reg: 'us', name: 'Trinidad and Tobago' },
  { value: 'TN', region: 'global/en', reg: 'global', name: 'Tunisia' },
  { value: 'TR', region: 'global/en', reg: 'global', name: 'Turkey' },
  { value: 'TM', region: 'global/en', reg: 'global', name: 'Turkmenistan' },
  { value: 'TC', region: 'us/en', reg: 'us', name: 'Turks and Caicos Islands' },
  { value: 'TV', region: 'sg/en', reg: 'sg', name: 'Tuvalu' },
  { value: 'UM', region: 'sg/en', reg: 'sg', name: 'U.S. Outlying Islands' },
  { value: 'VI', region: 'us/en', reg: 'us', name: 'U.S. Virgin Islands' },
  { value: 'UG', region: 'global/en', reg: 'global', name: 'Uganda' },
  { value: 'UA', region: 'global/en', reg: 'global', name: 'Ukraine' },
  { value: 'AE', region: 'global/en', reg: 'global', name: 'United Arab Emirates' },
  { value: 'GB', region: 'uk/en', reg: 'uk', name: 'United Kingdom' },
  { value: 'UY', region: 'us/en', reg: 'us', name: 'Uruguay' },
  { value: 'UZ', region: 'global/en', reg: 'global', name: 'Uzbekistan' },
  { value: 'VU', region: 'sg/en', reg: 'sg', name: 'Vanuatu' },
  { value: 'VA', region: 'global/en', reg: 'global', name: 'Vatican City' },
  { value: 'VE', region: 'us/en', reg: 'us', name: 'Venezuela' },
  { value: 'VN', region: 'sg/en', reg: 'sg', name: 'Vietnam' },
  { value: 'WF', region: 'sg/en', reg: 'sg', name: 'Wallis and Futuna' },
  { value: 'EH', region: 'global/en', reg: 'global', name: 'Western Sahara' },
  { value: 'YE', region: 'global/en', reg: 'global', name: 'Yemen' },
  { value: 'ZM', region: 'global/en', reg: 'global', name: 'Zambia' },
  { value: 'ZW', region: 'global/en', reg: 'global', name: 'Zimbabwe' },
];

export default CountriesList;
