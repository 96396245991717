/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import parse from 'html-react-parser';

/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Styles */
/* Services */

/**
 * Returns Quote Component
 *
 * @param sectionContent
 */

const Quote = ({ sectionContent }) => {
  // console.log("Quote", sectionContent?.quote)
  return (
    <Box maxW={1330} mx="auto" width="100%">
      <Flex
        mx="auto"
        alignItems="center"
        justifyContent="center"
        pos="relative"
        flexDir={{ base: 'column', md: 'row', xl: 'row' }}
        //  h={{base:'453.4px',xl:'236px'}}
        minW="291px"
        maxW="670px"
        w="100%"
        pb={{ base: '45.5px', md: '45px', xl: '59.13px' }}
        pt={{ base: '24.61', md: '44.61px', xl: '44.61px' }}
      >
        <Box maxW="375px" w="100%">
          <Box
            pt="30.3px"
            p={{
              base: '0px 35px 0px 34px',
              md: '21px 0 0 0',
              xl: '30px 0 0 0',
            }}
            //  as="h6"
            fontSize="26px"
            color="#617437"
            fontFamily="Zilla Slab"
            fontWeight="500"
            fontStyle="italic"
            textAlign={{ base: 'center', lg: 'left' }}
            // lineHeight={{ base: '25.2px', md: '44.29px' }}
            lineHeight="40px"
            minW={{ base: '291px' }}
            w="100%"
            // h={{base:'160px'}}
          >
            {sectionContent?.quote && (
              <Box as="blockquote">
                {parse(sectionContent?.quote?.replace(/\n/g, '<span />'))}
              </Box>
            )}
          </Box>
          <Flex
            justifyContent="center"
            pt={{ base: '20px', md: '39px', xl: '28px' }}
            opacity="0.8"
            textAlign="center"
          >
            <LazyLoadImageComponent
              src="/assets/images/signature_name.png"
              alt="sadhguru's signature"
              h={{ base: '52px', lg: '58px' }}
              w={{ base: '110px', md: '123px' }}
            />
          </Flex>
        </Box>
        <Box
          ml={{ base: '16px', xl: '20px' }}
          // w={{ base: '268px' }}
          pt={{ base: '29.4px', md: '0px', xl: '0px' }}
          // h={{ base: '192.44px' }}
          mb={{ base: '20px', md: '60px' }}
        >
          <LazyLoadImageComponent
            src={`${config.imgPath}/d/46272/1703153070-universal_quote_llustration.png`}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default Quote;
